<template>
  <div class="#w-full #rounded-[6px]">
    <div
      class="#group #flex #justify-between #items-start #gap-x-3 #cursor-pointer #w-max #border-b-[1px] #border-dotted #border-b-[#EB980C]"
      :class="{ '!#rounded-b-none': isOpen }"
      @click="
        () => {
          isOpen = !isOpen;
        }
      "
    >
      <h4
        class="#font-bold #text-sm #text-[#EB980C] #mb-1 #select-none #transition-colors group-hover:#text-vividmagenta"
      >
        {{ isOpen ? `${__("Toon minder info")}` : `${__("Toon meer info")}` }}
      </h4>
      <svg-use
        id="angle-down"
        type="regular"
        class="#w-[1em] #h-[1em] #fill-[#EB980C] #transition-colors group-hover:#fill-vividmagenta #mt-1"
        :class="{ '#rotate-180': isOpen }"
      />
    </div>
    <div
      class="#overflow-hidden #transition-all #pt-[5px] #text-inkdropdark #rounded-r-[4px] #rounded-bl-[4px]"
      :class="isOpen ? '#max-h-[300px] #py-[15px]' : '#max-h-0'"
    >
      <slot name="info" />
    </div>
  </div>
</template>

<script>
import { lang } from "../mixins";
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
